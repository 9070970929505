.ratiosContainer {
  width: 28vw;
  max-width: 300px;
  min-width: 150px;
  overflow: hidden;
  padding: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.ratioButton {
  width: 100.1%; // needed to fix render issues
  height: 30px;
  display: flex;
  border: none;
  padding: 0;
  background: none;
  overflow: hidden;
}

.chartBar {
  height: 100%;

  &.unscored {
    background-color: #6c757d;
  }
  &.blacklisted {
    background-color: #dc3545;
  }
  &.interested {
    background-color: #0dcaf0;
  }
  &.invested {
    background-color: #198754;
  }
  &.tfeb {
    background-color: #0d6efd;
  }
}