.emptyState {
  text-align: center;
  .noDataImage {
    margin-bottom: 10px;
    height: 100px;
  }
}

.idTable {
  display: flex;
  gap: 8px;
}

.scoreChart {
  width: 105px;
}

.table {
  transition: opacity 0.3s ease-in-out;
  &.loading {
    opacity: 0.5;
  }
  th, td {
    vertical-align: middle;
  }

  a, a:active {
    color: var(--bs-table-color)
  }

  .rowEntry {
    cursor: pointer;
  }

  .matchDateColumn {
    font-size: 0.9em;
  }
}

.tableContainer {
  position: relative;
}

div.spinner {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  z-index: 1;
}

.filterBadge {
  cursor: pointer;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin: 0 0 0 4px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.filtersContainer {

  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out 50ms, overflow 1ms linear 301ms;
  overflow: hidden;

  &.showFilters {
    transition: opacity 300ms ease-in-out 50ms, max-height 300ms ease-in-out 1ms, overflow 1ms linear;
    max-height: 308px;
    opacity: 1;
    overflow: visible;
  }

  .filterContent {
    padding: 0px 0 18px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .filterInputs {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: space-between;

      .toggleFilter {
        width: 150px;
        display: block;
        text-align: left;
        position: relative;

        &::after {
          position: absolute;
          right: 8px;
          top: 18px;
        }
      }
    }
  }
}
.filterResults {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.success {
  color: var(--bs-success);
}
.danger {
  color: var(--bs-danger);
}

.icon {
  font-size: 1.5em;
}


.noBtn {
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    background: none;
    border: none;
  }
}

.truncate {
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

