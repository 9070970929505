.footer {
  position: relative;
}

.loading {
  position: absolute;
  left: 0;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.errorAlert {
  margin-top: var(--bs-alert-margin-bottom);
  margin-bottom: 0;
}