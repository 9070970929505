.ldsRing {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  opacity: 1;
  transition: opacity 300ms ease-in-out 1ms;
  &:not(.show) {
    width: 0px !important;
    height: 0px !important;
    opacity: 0;
    transition: opacity 300ms ease-in-out, width 1ms linear 300ms, height 1ms linear 300ms;
  }
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    margin: 8px;
    border: 8px solid var(--bs-body-color);
    border-radius: 50%;
    border-color: var(--bs-body-color) transparent transparent transparent;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  &.show {
    div {
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}