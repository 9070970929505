.cardLink {
  color: unset;
  text-decoration: unset;
  &:hover {
    color: unset;
  }
}

.grantCard {
  width: 100%;
  overflow: hidden;
  padding: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background: var(--card-bg);
  display: flex;
  align-items: center;
  .picture {
    width: 200px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  .info {
    height: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .raised {
      font-size: 1.5em;
    }
  }
}