.titleContainer {
  display: flex;
  .toggleTheme {
    margin-right: 10px;
    border-radius: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logoutButton {
  border: none;
  background: none;
  text-align: left;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  color: var(--bs-nav-link-color);

  &:hover {
    color: var(--bs-nav-link-hover-color);
  }
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}