.listButton {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  max-height: 60vh;
  overflow: auto;
  padding: 16px 0;
  margin-top: 5px;
}

.badges {
  display: flex;
  gap: 5px;
  margin: 5px 0 20px 0;
  flex-wrap: wrap;
}

.filterBadge {
  cursor: pointer;
  background-color: #FFF;
  border: none;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin: 0 0 0 4px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}