.multiSelectBox {
  position: relative;
  z-index: 1;
}

.multiSelectGroup {
  position: relative;
}

.dropdownMenu {
  max-height: 50vh;
  overflow-y: auto;
}

.multiSelectOptions {
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  & > div {
    width: 100%;
  }

  .selected {
    color: var(--bs-success)
  }
}

.overlay {
  position: fixed;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  z-index: 1;

  &.show {
    width: 100%;
    height: 100%;
  }
}