.orderButton {
  color: var(--bs-table-color);
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  gap: 8px;
  align-items: center;

  i {
    margin: 0;
  }
}