
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

body:not(.dark) {
  --card-bg: rgb(252, 252, 252);
}

.dark {

  /* redefine theme colors for dark theme */
  $primary: #0056ac;
  $secondary: #111111;
  $success: #008a22;
  $dark: #000;

  $theme-colors: (
      "primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "danger": $danger,
      "info": $indigo,
      "dark": $dark,
      "light": $light,
  );

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
      --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
  @each $color, $value in $theme-colors-rgb {
      --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $body-color: #eeeeee;
  $body-bg: #263C5C;

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  --card-bg: rgba(0, 27, 45, 0.9);

  .bg-light {
    --bs-badge-color: var(--bs-body-bg);
  }

  .modal-content {
    --bs-modal-bg: var(--bs-body-bg);
  }

  .modal-header .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23eee%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
  }

  .nav-tabs, .nav-tabs .nav-link.active {
    --bs-nav-tabs-link-active-color:  var(--bs-body-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-border-color: var(--bs-blue);
    --bs-nav-tabs-link-active-border-color: var(--bs-blue);
    --bs-nav-tabs-link-hover-border-color: var(--bs-blue);
    --bs-nav-link-color: var(--bs-gray-500);
    --bs-nav-link-hover-color: var(--bs-gray-300);
  }
  .nav-tabs .nav-link.active {
    border-bottom: 1px solid var(--bs-body-bg);
  }
}
