.biosListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  padding-bottom: 40px;
  transition: opacity 0.3s ease-in-out;

  &.loading {
    opacity: 0.5;
  }
}


.account {
  --bs-btn-border-radius: 0.375rem;
  background-color: var(--card-bg);
  padding: 20px 35px;
  border-radius: var(--bs-btn-border-radius);
  border-radius: var(--bs-btn-border-radius);
  box-shadow: 0 30px 40px rgb(0 0 0 / 10%);
  .accountHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
    h5 span {
      font-size: 0.6em;
    }

    .markAsRead {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      font-size: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      color: green;
    }
  }
  .accountDescriptions {
    margin-top: 20px;
  }
}

