.table th, .table  td {
  vertical-align: middle;
}


.icon {
  font-size: 1.5em;
  color: var(--bs-table-color);
}

.success {
  color: var(--bs-success);
}
.danger {
  color: var(--bs-danger);
}

.emptyState {
  text-align: center;
  .noDataImage {
    margin-bottom: 10px;
    height: 100px;
  }
}

.noBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.limitedTextSize {
  display: block;
  width: 80px;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}
