.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  transition: opacity 0.3s ease-in-out;
  h1 {
    margin: 0;
  }
  a {
    color: var(--bs-body-color);
    h2 {
      font-size: 1em;
    }
  }
  .scoreChart {
    width: 80px;
  }
}

.infos {
  margin: 16px 0;;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.additionalContent {
  transition: opacity 0.3s ease-in-out;
}

.matchNotFound {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.keywordsList {
  display: flex;
  gap: 4px;
  font-size: 1.3em;
}

.success {
  color: var(--bs-success);
}
.danger {
  color: var(--bs-danger);
}

.icon {
  font-size: 1.5em;
}


.noBtn {
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    background: none;
    border: none;
  }
}

.spinning {
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(359deg)
  }
}

.isLoading {
  opacity: 0.5;
}

div.spinner {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  z-index: 1;
}

.truncate {
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}