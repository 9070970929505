.tablePager {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0;
}

.Pager {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.leftActions {
  display: flex;
  gap: 8px;
}