.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  margin: 20px;
}

.errorImage {
  max-width: 100%;
  width: 300px;
  height: auto;
}