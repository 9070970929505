.hero {
  margin: 18px 0 36px 0;
  transition: opacity 0.3s ease-in-out;
}

.poiContent {
  transition: opacity 0.3s ease-in-out;
}

.titleWithActions {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isLoading {
  opacity: 0.5;
}

div.spinner {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  z-index: 1;
}

.poiNotFound {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}
