.dropdownCategory {
  font-size: 0.8em;
  opacity: 0.6;
  padding-bottom: 10px !important;
  &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
  }
}

.cardsList {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 10px;
  transform: opacity 0.3s linear;
  &.loading {
    opacity: 0.5;
  }
}